import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Header from "components/Headers/Header.js";
import { useToasts } from 'react-toast-notifications';
import { btnCss, DayEndReportStatus } from '../../appConfig';
import { postMethod, getUserId } from "../../services/httpServices";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { getSortCls, reportloaderContainer, reportloader } from "services/util";
import FullPageLoader from "components/FullPageLoader/fullpageloader";


const STATUS = DayEndReportStatus;

const sortIS = {
  sortSubscriberName: 0,
  sortProductId: 0,
  sortReceivingBank: 0,
  sortAmount: 0,
  sortFeesAmount: 0,
  sortStatus: 0
};

const DayEndReport = () => {

  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [reportDate, setReportDate] = React.useState('');
  const [response, setResponse] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [xlLoading, setXlLoading] = React.useState(false);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [submitButton, setSubmitButton] = React.useState(false);

  const { addToast } = useToasts();

  React.useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [currentPage]);

  React.useEffect(() => {
    if (pdfLoading) {
      fetchReportData();
    }
  }, [pdfLoading]);

  React.useEffect(() => {
    if (xlLoading) {
      fetchReportData();
    }
  }, [xlLoading]);

  React.useEffect(() => {
    if (isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchReport();
      }
    }
  }, [sort]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  }

  const validate = () => {
    var error = {};
    if (!reportDate) {
      error.reportDate = 'Please select date';
    }
    return error;
  }

  const onSubmitReport = () => {
    setSubmitButton(true);
    fetchReport();
  }

  const fetchReport = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      // setResponse(false);
      fetchSettlement();
    }
  }

  const exportPDF = (reportData) => {
    // setPdfLoading(true);
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Day End Report - " + moment(new Date(reportDate)).format('DD/MM/YYYY');
    const headers = [["Payment By", "Product Id", "Payment From", "Amount", "Fees", "Status"]];

    const data = reportData.map(elt =>
      [elt.subscriberName, elt.productId, elt.receivingBank, 
      getAmt(elt.amount), getAmt(elt.feesAmount), statusFormat(elt.status)]
    );

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
    setTimeout(() => {
      setPdfLoading(false);
    }, 1000);
  }

  const getColumnName = (i) => {
    return (i >= 26 ?
      getColumnName(Math.floor(i / 26) - 1) : '')
      + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26];
  }

  const exportToCSV = (reportData) => {
    setXlLoading(true);
    const header = ["subscriberName", "productId", "receivingBank", "amount", "feesAmount", "status"];
    const originalHeader = [...header];


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    var Heading = [
      ["Day End Report - " + moment(new Date(reportDate)).format('DD/MM/YYYY')],
      ["Payment By", "Product Id", "Payment From", "Amount", "Fees", "Status"]
    ];

    var ws = XLSX.utils.aoa_to_sheet(Heading);
    const newReportData = reportData.map(elt => {
      const obj = Object.assign({}, elt);
      obj.status = statusFormat(obj.status);
      obj.amount = getAmt(obj.amount);
      obj.feesAmount = getAmt(obj.feesAmount);
      return obj;
    });

    XLSX.utils.sheet_add_json(ws, newReportData, {
      header: header,
      skipHeader: true,
      origin: -1
    });

    // For setting width of columns
    var wscols = [];
    for (var i = 0; i < header.length; i++) {  // columns length added
      wscols.push({ wch: header[i].length + 5 })
    }
    ws["!cols"] = wscols;

    // For removing other columns

    if (ws['!cols']) {
      const original = getColumnName(ws['!cols'].length > 0 ? ws['!cols'].length - 1 : 0);
      const replace = getColumnName(originalHeader.length > 0 ? originalHeader.length - 1 : 0);

      // For removing other columns
      ws['!ref'] = ws['!ref'].replace(original, replace);
    }

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Report' + fileExtension);
    setTimeout(() => {
      setXlLoading(false);
    }, 1000);
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    return ob && ob.value ? ob.value : val 
  };

  const getAmt = (amount) => {
    let amt = Number(amount);
    return amt.toLocaleString();
  }

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const fetchSettlement = async () => {
    let errorMsg = '';
    setLoading(true);
    let id = getUserId();
    let data = {
      'MerchantProfileId': getUserId(),
      'FromDate': moment(new Date(reportDate)).format('DD/MM/YYYY'),
      'ToDate': moment(new Date(reportDate)).format('DD/MM/YYYY'),
      "Status": "",
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    }

    if (sort.sortSubscriberName == "1") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = false;
    } else if (sort.sortSubscriberName == "2") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortStatus == "1") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = false;
    } else if (sort.sortStatus == "2") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = true;
    } else if (sort.sortReceivingBank == "1") {
      data['SortingOn'] = 'ReceivingBank';
      data['IsDescending'] = false;
    } else if (sort.sortReceivingBank == "2") {
      data['SortingOn'] = 'ReceivingBank';
      data['IsDescending'] = true;
    } else if (sort.sortProductId == "1") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = false;
    } else if (sort.sortProductId == "2") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = true;
    } else if (sort.sortFeesAmount == "1") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = false;
    } else if (sort.sortFeesAmount == "2") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = true;
    }

    postMethod('/api/Reports/MerchantSettlementReport', JSON.stringify(data), 'post')
      .then(res => {
        setLoading(false);
        setResponse(true);
        setIsMounted(true);
        setSubmitButton(false);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      })
  }

  const fetchReportData = async () => {
    let errorMsg = '';
    let id = getUserId();
    let data = {
      'MerchantProfileId': getUserId(),
      'FromDate': moment(new Date(reportDate)).format('DD/MM/YYYY'),
      'ToDate': moment(new Date(reportDate)).format('DD/MM/YYYY'),
      "Status": "",
      'PageNumber': 1,
      'PerPage': totalRec
    }

    if (sort.sortSubscriberName == "1") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = false;
    } else if (sort.sortSubscriberName == "2") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortStatus == "1") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = false;
    } else if (sort.sortStatus == "2") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = true;
    } else if (sort.sortReceivingBank == "1") {
      data['SortingOn'] = 'ReceivingBank';
      data['IsDescending'] = false;
    } else if (sort.sortReceivingBank == "2") {
      data['SortingOn'] = 'ReceivingBank';
      data['IsDescending'] = true;
    } else if (sort.sortProductId == "1") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = false;
    } else if (sort.sortProductId == "2") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = true;
    } else if (sort.sortFeesAmount == "1") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = false;
    } else if (sort.sortFeesAmount == "2") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = true;
    }

    postMethod('/api/Reports/MerchantSettlementReport', JSON.stringify(data), 'post')
    .then(res => {
      if (
        res && res.data
      ) {

        if (res.data.responseCode === "200" &&
          res.data.result) {
          // Success
          if (
            !res.data.result.list ||
            !res.data.result.list.length
          ) {
            res.data.result.list = [];
          }
          setReportData(res.data.result.list);
          // setFilteredList(res.data.result.list)
          // let pc = Math.ceil(res.data.result.totalRecords / pageSize);
          // setTotalRec(res.data.result.totalRecords);
          // setPageCount(pc);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
          reportDataErr();
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
          reportDataErr();

        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        reportDataErr();

      }
    }).catch(err => {
      reportDataErr();
      notifyFailed('Internal Server Error');
    })
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {

    setCurrentPage(selectedPage);
  }

  const setReportData = (resp)=> {
    if (pdfLoading) {
      exportPDF(resp);
    } else if (xlLoading) {
      exportToCSV(resp);
    }
  }

  const reportDataErr = () => {
    setPdfLoading(false);
    setXlLoading(false);
  }

  const exportPDFCb = () => {
    setPdfLoading(true);
  }

  const exportCSVCb = () => {
    setXlLoading(true);
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline"
                  onSubmit={e => { e.preventDefault(); }}>
                  <label for="status"
                    className="form-control-label">
                    Select Date
                  </label>

                  &nbsp; &nbsp;
                  <FormGroup className="mb-0">
                    <div class="dropdown-border">
                      <Input
                        type="date"
                        id="status"
                        className="form-control-alternative status-filter"
                        name="reportDate"
                        max={moment().format("YYYY-MM-DD")}
                        onChange={(e) => setReportDate(e.target.value)}
                      >
                      </Input>
                    </div>
                  </FormGroup>

                  &nbsp; &nbsp; &nbsp;

                  <Button className="mt-4 mb-4 mr-xl-9" color="primary"
                    onClick={onSubmitReport}
                    disabled={loading}>
                    Submit &nbsp; &nbsp;
                    {loading && submitButton &&<i class="fa fa-spinner fa-spin"></i>}
                  </Button>


                  {response &&
                    <>
                      {/* &nbsp;&nbsp;&nbsp;&nbsp; */}

                      <label className="ml-xl-9">Export</label>

                      &nbsp; &nbsp; &nbsp;
                      <Button color="primary"
                        size="sm"
                        disabled={pdfLoading}
                        onClick={exportPDFCb}
                      >
                        PDF {pdfLoading && <i class="fa fa-spinner fa-spin"></i>}
                      </Button>

                      &nbsp; &nbsp;

                      <Button color="primary"
                        size="sm"
                        disabled={xlLoading}
                        onClick={exportCSVCb}
                      >
                        EXCEL {xlLoading && <i class="fa fa-spinner fa-spin"></i>}
                      </Button>

                    </>}

                </Form>

              </CardHeader>

              {response && <>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortSubscriberName, 'sortSubscriberName')}>
                        Payment By
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortSubscriberName)}
                            onClick={() => sortHandle(sort.sortSubscriberName, 'sortSubscriberName')}
                          />
                        </button>
                      </th>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}>
                        Product Id
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortProductId)}
                            onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}
                          />
                        </button>
                      </th>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortReceivingBank, 'sortReceivingBank')}>
                        Payment From
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortReceivingBank)}
                            onClick={() => sortHandle(sort.sortReceivingBank, 'sortReceivingBank')}
                          />
                        </button>
                      </th>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
                        Amount
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortAmount)}
                            onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                          />
                        </button>
                      </th>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')}>
                        Fees
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortFeesAmount)}
                            onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')}
                          />
                        </button>
                      </th>
                      <th scope="col"
                        onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
                        Status
                        <button style={btnCss} >
                          <i className={getSortCls(sort.sortStatus)}
                            onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                          />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList.length > 0 && filteredList.map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">
                            {mmo.subscriberName}
                          </th>
                          <td>{mmo.productId ? mmo.productId : '--'}</td>
                          <td>{mmo.receivingBank}</td>
                          {/* <td>{moment(mmo.createdAt).format('lll')}</td> */}
                          <td>{getAmt(mmo.amount)}</td>
                          <td>{getAmt(mmo.feesAmount)}</td>
                          <td>{statusFormat(mmo.status)}</td>
                        </tr>

                      )
                    }
                    )}
                  </tbody>
                  {loading && 
                    <FullPageLoader label={"Fetching..."} 
                      loaderContainer={reportloaderContainer}
                      loader={reportloader}
                    />
                  }
                  {!loading && !filteredList.length && 
                    <NoRecordFound 
                      loaderContainer={reportloaderContainer}
                      loader={reportloader}
                    />
                  }                
                </Table>
                <CardFooter className="py-4">
                  {filteredList.length > 0 &&
                    <Row>
                      <Col lg="6">
                        <div>
                          <small>
                            <strong>
                              Total Records : {totalRec}
                            </strong>
                          </small>
                        </div>
                      </Col>

                      <Col lg="6" >
                        <nav aria-label="...">
                          <div className="float-right">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pagesCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={"paginationV2"}
                              subContainerClassName={"pages paginationV2"}
                              activeClassName={"active"}
                              forcePage={currentPage} />
                          </div>
                        </nav>

                      </Col>
                    </Row>}
                </CardFooter>
              </>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DayEndReport;
