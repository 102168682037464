import React from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input, InputGroupText, InputGroupAddon, InputGroup,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { checkSpace, postMethod, getUserId } from "services/httpServices";
import { postMethodDefaultToken } from "services/httpServices";
import swal from 'sweetalert';



const initialValue = {
  firstName: 'Inderjeet',
  lastName: 'Kaur',
  mobileCode: '23458',
  phone: '984983',
  email: 'ik@gmail.com',
  password: 'Inder@123',
  oneFAAmt: '',
  authMode: ''
};

const VerifyUser = (props) => {
  const [values, setValues] = React.useState(props);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const { addToast } = useToasts();


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === "otp") {
        if (checkOTP(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const validateOTP = (e) => {
    e.preventDefault();
    if (checkOTPTab()) {
      // Call verify-otp api
      verifyotp();
    } else {
    }
  }

  const verifyotp = () => {
    setLoading(true);
    let data = {
      'Email': values.email ? values.email : '',
      'Mobilenumber': values.phone,
      'FirstName': values.firstName,
      'LastName': values.lastName,
      'Otp': values.otp
    }
    let errorMsg = '';
    postMethodDefaultToken('/api/Auth/VerifySelfOtp', data, 'post')
    .then(res => {
      setLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          let msg = res.data.responseMessage || 'Otp Verified';
          openModal(msg);

        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in verifying otp';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in verifying otp';
          notifyFailed(errorMsg);

        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);

      }
    }).catch(err => {
      setLoading(false);
      notifyFailed('Internal Server Error');
    });
  }

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons:
      {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    }).then((confirm) => {
      if (confirm) {
        props.parentCallback(values);
      } else {
        props.parentCallback(values);
      }
    });
  }

  const checkOTPTab = () => {
    let error = {};
    let valid = true;
    if (!values.otp) {
      error.otp = 'OTP is Required';
      valid = false
    }
    setErrors(error);
    return valid;
  }

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const resendOtp = (e) => {
    e.preventDefault();
    setSpinner(true);
    setValues({...values, 'otp': ''});
    genOtp();
  }

  const genOtp = () => {
    let data = {
      'Email': values.email ? values.email : '',
      'Mobilenumber': values.mobWithoutCC,
      'FirstName': values.subscriberType && values.subscriberType == 'Individual' ? values.firstName.trim() :
      values.organisationName,
      'LastName': values.lastName,
      'MobileCode': Number(values.mobileCode)
    }
    let errorMsg = '';
    postMethodDefaultToken('/api/Auth/SelfOtp', data, 'post')
      .then(res => {
        setSpinner(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success

          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in generating otp';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in generating otp';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setSpinner(false);
        notifyFailed('Internal Server Error');
      })
  }

  const goBack = () => {
    props.goBack();
  }

  return (
    <>
      <Form onSubmit={validateOTP} autoComplete="off">
        <Row className="mb-3">
          <Col>
            <strong>Profile Details</strong>
            <div className="mt-2">
              <div>
                {values.subscriberType == 'Individual' ? <small>
                  Name : {values.firstName} {values.lastName}</small> :
                  <small>
                  Name : {values.organisationName}</small>
                }
              </div>
              <div>
                <small className="mt-2">Phone : {`(${values.mobileCode}) - ${values.mobWithoutCC}`}</small>
              </div>
              <div>
                <small className="mt-2">E-mail : {values.email}</small>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <label
                className="form-control-label text-black-50"
                htmlFor="input-otp">
                OTP
              </label>
              <div className="float-right text-small">
                <small>
                  <a href="#" onClick={resendOtp}>
                    Resend
                  </a> &nbsp; &nbsp;
                  {spinner &&
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>}
                </small>
              </div>

              <Input
                className="form-control-alternative"
                id="input-otp"
                placeholder="Enter OTP"
                type="text"
                name="otp"
                value={values.otp}
                onChange={handleInputChange}
              />
              <div className="text-left text-muted">
                <small className="text-black-50"><i style={{ fontSize: '12px' }}>*
                  we have sent you an OTP on your registered mobile number / email</i></small>
              </div>
              <div></div>
              {errors.otp && <div className="text-left text-danger">
                <small>* {errors.otp}</small>
              </div>}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg-6>
            <div>
              <Button className="sign-up-btn float-left" color="primary"
                onClick={goBack}>
                <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
              </Button>
            </div>
          </Col>
          <Col lg-6>
            <div>
              <Button className="sign-up-btn float-right" color="primary" type="submit"
                disabled={loading}>
                Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
              </Button>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
}

export default VerifyUser;